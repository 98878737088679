





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InsNavLayout1 extends Vue {
  @Prop() private navData!: object;
}
